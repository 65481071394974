import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
    redirect: '/index',
    children:[
      {
        path: '/index',
        name: 'index',
        component: () => import('@/views/index.vue')
      },
      {
        path: '/institutesInfo',
        name: 'institutesInfo',
        component: () => import('../views/InstitutesInfo.vue')
      },
      {
        path: '/honor',
        name: 'honor',
        component: () => import('@/views/honorView.vue')
      },
      {
        path: '/leaderShip',
        name: 'leaderShip',
        component: () => import('@/views/leaderShip.vue')
      },
      {
        path: '/projectDistribution',
        name: 'projectDistribution',
        component: () => import('@/views/projectDistribution.vue')
      },
      {
        path: '/organizationChart',
        name: 'organizationChart',
        component: () => import('@/views/organizationChart.vue')
      },
      {
        path: '/newsletter',
        name: 'newsletter',
        component: () => import('@/views/newsletter.vue')
      },

      {
        path: '/wenhua',
        name: 'wenhua',
        component: () => import('@/views/wenhuaView.vue')
      },
      {
        path: '/yuannei',
        name: 'yuannei',
        component: () => import('@/views/news/newView.vue')
      },
      {
        path: '/hangye',
        name: 'hangye',
        component: () => import('@/views/news/newView.vue')
      },
      {
        path: '/gaikuang',
        name: 'gaikuang',
        component: () => import('@/views/gaikuang.vue')
      },
      {
        path: '/xueshu',
        name: 'xueshu',
        component: () => import('@/views/news/newView.vue')
      },
      {
        path: '/zhengce',
        name: 'zhengce',
        component: () => import('@/views/news/newView.vue')
      },
      {
        path: '/yuanneinotice',
        name: 'yuanneinotice',
        component: () => import('@/views/news/noticeView.vue')
      },
      {
        path: '/huiyinotice',
        name: 'huiyinotice',
        component: () => import('@/views/news/noticeView.vue')
      },
      {
        path: '/cooperation',
        name: 'cooperation',
        component: () => import('@/views/cooperationView.vue')
      },

      {
        path: '/teamView',
        name: 'teamView',
        component: () => import('@/views/teamView.vue')
      },
      {
        path: '/projects',
        name: 'projectsView',
        component: () => import('@/views/scientificPayoffs/projectsView.vue')
      },
      {
        path: '/copyright',
        name: 'copyrightView',
        component: () => import('@/views/scientificPayoffs/copyright.vue')
      },
      {
        path: '/award',
        name: 'awardView',
        component: () => import('@/views/scientificPayoffs/award.vue')
      },
      {
        path: '/digitalYantai',
        name: 'digitalYantai',
        component: () => import('@/views/digitalYantai.vue')
      },
      {
        path: '/job',
        name: 'jobView',
        component: () => import('@/views/aboutUs/job.vue')
      },
      {
        path: '/salaryPackage',
        name: 'salaryPackage',
        component: () => import('@/views/aboutUs/salaryPackage.vue')
      },
      {
        path: '/teamBuilding',
        name: 'teamBuilding',
        component: () => import('@/views/aboutUs/teamBuilding.vue')
      },
      {
        path: '/trainingAndDevelopment',
        name: 'trainingAndDevelopment',
        component: () => import('@/views/aboutUs/trainingAndDevelopment.vue')
      },
      {
        path: '/careerPlanning',
        name: 'careerPlanning',
        component: () => import('@/views/aboutUs/careerPlanning.vue')
      },
      {
        path: '/designView',
        name: 'designView',
        component: () => import('@/views/business/designView.vue')
      },
      {
        path: '/scientificResearch',
        name: 'scientificResearch',
        component: () => import('@/views/business/scientificResearch.vue')
      },
      {
        path: '/learn',
        name: 'learnVideo',
        component: () => import('@/views/scientificPayoffs/learn.vue')
      },
    ]
  },

]

const router = new VueRouter({
  mode: 'history',
  routes
})


export default router
