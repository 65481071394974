import request from './axios';

const base = '/api/tenant/web/webStation'

export const getArticlePage = (param) => request({
    url: base+ '/news/page',
    method: 'get',
    params: param
});

export const getMemberPage = (param) => request({
    url: base+ '/member/page',
    method: 'get',
    params: param
});

export const searchArticlePage = (param) => request({
    url: base+ '/search',
    method: 'get',
    params: param
});
