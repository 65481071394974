
import axios from 'axios'

axios.defaults.timeout = 10000;
//返回其他状态吗
axios.defaults.validateStatus = function (status) {
  return status >= 200 && status <= 500; // 默认的
};
//跨域请求，允许保存cookie
axios.defaults.withCredentials = true;


//HTTPrequest拦截
axios.interceptors.request.use(config => {

  const meta = (config.meta || {});

  config.headers.Authorization = 'Basic dGVuYW50OjEyMzQ1Ng=='
  //headers中配置serialize为true开启序列化
  if (config.method === 'post' && meta.isSerialize === true) {
    config.data = serialize(config.data);
  }
  return config
}, error => {
  return Promise.reject(error)
});
//HTTPresponse拦截
axios.interceptors.response.use(res => {
  const status = Number(res.status) || 200;
  const message = res.data.msg || '未知错误';

  // 如果请求为非200否者默认统一处理
  if (status !== 200) {
    return Promise.reject(new Error(message))
  }
  if (res.data.code&&res.data.code !== 200) {

    return Promise.reject(new Error(res.data.msg))
  }
  return res;
}, error => {

  return Promise.reject(new Error(error));
})
export const serialize = data => {
  let list = [];
  Object.keys(data).forEach(ele => {
    list.push(`${ele}=${data[ele]}`)
  })
  return list.join('&');
};
export default axios;
