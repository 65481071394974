import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/custom.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Avue from '@smallwei/avue';
import '@smallwei/avue/lib/index.css';
import axios from 'axios'
import AvueUeditor from 'avue-plugin-ueditor'
import Print from 'vue-print-nb'
import BaiduMap from 'vue-baidu-map'
Vue.use(BaiduMap, {

  ak: '71NDl0DOgFB08iCdfEOr8shotEnSEzfD'
})
ElementUI.Dialog.props.lockScroll.default = false;
ElementUI.Drawer.props.modalAppendToBody.default = false;

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(Avue,{axios});
Vue.use(AvueUeditor);

Vue.use(Print)
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

